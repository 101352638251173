<template>
  <Card class="ob__question-card" size="small">
    <template v-slot:header>
      <p class="question-card__number">{{question.title}}</p>
    </template>
    <template v-slot:body>
      <h3 class="question-card__question">{{ question.onboardingQuestion }}</h3>

      <ObPrompt
        class="question-card__prompt"
        v-if="hasPrompt"
        :icon-name="question.promptIcon"
        :content="question.prompt"
        :icon-size="48"
      />

      <template v-if="question.type === 'list'">
        <ListEditorBlock mode="onboarding-list" :placeholder="question.example" v-model="answer" :id="'question-card-number-' + number" placeholder="Your answer" class="question-card__answer"/>
        <ObRules class="question-card__rules"/>

      </template>
      <template v-else-if="isEnd">
        <div class="invite-wrapper">
          <InviteForm remove-header v-model="answer.value"/>
        </div>
      </template>
      <template v-else>
        <TextArea
            :id="'question-card-number-' + number"
            :name="'question-card-number-' + number"
            class="question-card__answer"
            v-model="answer.value"
            :placeholder="question.example"
        ></TextArea>
      </template>
    </template>
    <template v-slot:footer>
      <div class="question-card__buttons-group">
        <Button
          :text="isEnd? 'Invite and finish' :'Next'"
          color="#fff"
          bgcolor="#0CAC60"
          @click="sendAnswer"
          style-modifiers="success"
          class="next-button"
        ></Button>
        <template v-if="isEnd">
          <Button
              text="Skip"
              color="#fff"
              bgcolor="#0CAC60"
              @click="toWorkspace"
              style-modifiers=""
              class="next-button"
          ></Button>
        </template>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import ObPrompt from "@/components/Onboarding/ObPrompt";
import TextArea from "@/components/UI-Kit/TextArea";
import Button from "@/components/UI-Kit/Button";
import { computed, toRef, reactive, toRefs, watch } from "vue";
import ObVideo from "@/components/Onboarding/ObVideo";
import ObRules from "@/components/Onboarding/ObRules";
import ContentEditBlock from "@/components/UI-Kit/TextEditorBlock";
import ListEditorBlock from "@/components/UI-Kit/ListEditor";
import InviteForm from "@/components/Dashboard/inviteForm";

export default {
  name: "QuestionCard",
  props: {
    answer: {
      type: Object
    },
    number: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    isEnd: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InviteForm,
    ListEditorBlock,
    ObVideo,
    Button,
    ObPrompt,
    Card,
    ObRules,
    ContentEditBlock,
    TextArea
  },
  setup: function(props, { emit }) {
    const hasPrompt = computed(
      () => props.question.prompt && props.question.prompt.length > 0
    );

    const sendAnswer = function() {
      emit("get-answer");

    };

    const toWorkspace = function () {
      emit("to-workspace")
    }

    return {
      hasPrompt,
      sendAnswer,
      toWorkspace
    };
  }
};
</script>

<style scoped lang="scss">
.ob__question-card {
  display: flex;
  text-align: left;
  max-width: 800px;
  align-items: self-start;
  min-width: 800px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35),
    0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px 24px;

}


.question-card__buttons-group {
  margin-top: 35px;
}

.question-card__question {
  font-weight: 500;
  font-size: 18px;

  line-height: 21px;
  color: #13246e;
  margin-top: 16px;
}

.question-card__answer {
  max-height: 300px;
  margin-top: 24px;
}

.question-card__number {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
  margin: 0;
}

.question-card__prompt {
  margin-top: 16px;
}

.question-card__rules {
  margin-top: 11px;
}

.next-button {
  width: 127px;
  height: 40px;
  margin-right: 10px;
}

.invite-wrapper {
  margin-top: 24px;
}
</style>
