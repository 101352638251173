<template>
  <div class="onboarding">
    <img
      src="@/assets/images/pxp-logo-white.svg"
      class="onboarding-logo"
      alt="pagexpage logo"
    />
    <template v-if="loading"> </template>
    <template v-else>
      <Checker
        id="onboarding-progress"
        :check-list="answerCheckList"
        class="onboarding-progress"
      />
      <p class="onboarding-description">Setting up your workspace</p>
      <div
        id="onboarding-slider"
        uk-slider="center: true; index: 0; finite: true; autoplay-interval: 1000;"
        class="uk-position-relative
                  uk-visible-toggle
                  uk-dark
                  onboarding-slider"
      >
        <ul class="uk-slider-items uk-grid onboarding-slider-items" >
          <template
            v-for="ind in questions.length"
            :key="'onboarding-question-' + ind"
          >
            <li :ind="ind - 1">
              <div class="uk-panel ">
                <QuestionCard
                  :number="ind"
                  :question="questions[ind - 1]"
                  :answer="answersModel[ind - 1]"
                  @get-answer="nextClick(ind - 1)"
                  @to-workspace="toWorkspace"
                  :is-end="ind === questions.length"
                ></QuestionCard>
              </div>
            </li>
          </template>
        </ul>
        <div class="uk-light">
          <a
            class="uk-position-center-left uk-slidenav-large uk-position-large"
            href="#"
            uk-slidenav-previous
            uk-slider-item="previous"
          />
          <a
            class="uk-position-center-right uk-slidenav-large uk-position-large"
            href="#"
            id="next"
            uk-slidenav-next
            uk-slider-item="next"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, reactive, inject, computed, isRef, nextTick } from "vue";
import { useRouter } from "vue-router";
import {
  saveCanvasData,
  getOnboardingQuestions
} from "@/api/canvas";
import UIkit from 'uikit';

import Checker from "@/components/UI-Kit/Checker";
import QuestionCard from "@/components/Onboarding/QuestionCard";
import {createWorkspaceRequestApi, inviteCollaboratorsRequestApi} from "@/api/workspace";

const initializationOnboardingApp = function(props) {
  
  const router = useRouter();
  const loading = ref(true);
  const questions = ref([]);
  const answerCheckList = ref([]);
  const answersModel = ref([]);
  const answers = ref([]);
  const active = ref(0);
  const workspaceId = ref(undefined);
  const getWorkspaces = inject("getWorkspaces")
  const workspaces = inject("workspaces")
  
  let sliderNext = undefined;

  const getQuestions = async function() {
    try {
      questions.value = await getOnboardingQuestions();
    } catch {
      alert("Failed to load questions");
    }
  };

  const getAnswers = function() {
    answersModel.value = questions.value.map((item) => item.type === 'list'? reactive(['']): ref(''));
    answerCheckList.value = answersModel.value.map((item) => isRef(item)? computed(() => item.value.length > 0): computed(() => item[0].length > 0));
    answers.value = answersModel.value.map((item) => isRef(item)? computed(() => item.value): computed(() => item.join('\n')));
  };

  const saveActiveCard = async function () {
    const activeCard = document.querySelector('li.uk-active')
    const index = Number.parseInt(activeCard.getAttribute('ind'));
    if (index < questions.value.length - 1) {
      const data = answers.value[index].value
      await saveCanvasData(index + 1, workspaceId.value,{ data })
    } else {
      const data = answers.value[index].value
      try {
        await inviteCollaboratorsRequestApi(workspaceId.value, data)
      } catch {}
      console.log({...workspaces.value})
      console.log(getWorkspaces)
      await getWorkspaces()
      console.log({...workspaces.value})
      await router.push({
        name: 'WorkspaceRedirect'
      })
    }
  }

  const nextClick = function () {
    sliderNext.click()
  }

  const toWorkspace = function () {
    router.push({
      name: 'Dashboard',
      params: {
        workspaceId: workspaceId.value
      }
    })
  }

  getQuestions().then(() => {
    getAnswers()
    createWorkspaceRequestApi({
      workName: '',
      workCover: '',
      workDescription: '',
    }).then((data) => {
      workspaceId.value = data.id
      loading.value = false;
      nextTick(() => {
        sliderNext = document.querySelector('a#next')
        sliderNext.addEventListener('click', () => {
          saveActiveCard()
        });
      })
    })
  });

  return {
    answersModel,
    answerCheckList,
    questions,
    loading,
    nextClick,
    answers,
    toWorkspace
  };
};

export default {
  name: "Onboarding",
  components: { QuestionCard, Checker },
  setup: initializationOnboardingApp
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.pxp__onboarding {
  /*display: grid;
  width: 100vw;
  height: 100vh;
  background-color: #0c2372;
  margin: 0;
  grid-template-areas:
    "ob__logo ob__rs"
    "ob__info ob__rs"
    "ob__question ob__rs";
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 5fr 8fr;*/
}

.onboarding__logo {
  background-color: #0c2372;
  grid-area: ob__logo;
  align-self: self-start;
  justify-self: left;
}

.onboarding__info {
  background-color: #0c2372;
  grid-area: ob__info;
  text-align: left;
  align-self: self-start;
  margin-left: 64px;
}

.onboarding__question {
  background-color: #0c2372;
  grid-area: ob__question;
  align-self: self-start;
  justify-self: center;
}

.onboarding__right-side {
  background-image: url("../assets/images/onboarding-right.png");
  grid-area: ob__rs;
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.logo__img {
  width: 180px;
  margin-left: 40px;
  margin-top: 40px;
}

.info__question {
  font-weight: bold;
  color: #1fe788;
  font-size: 32px;
  line-height: 38px;
}

.info__answer {
  color: white;
  font-size: 24px;
  line-height: 150.6%;
}

.onboarding {
  width: 100vw;
  height: 100vh;
  background-color: $pxp-blue-added-color;
  display: grid;
  grid-template-areas:
      "logo"
      "checker"
      "desc"
      "cards";
  grid-template-rows: 70px 28px 38px;
}

.onboarding-logo {
  grid-area: logo;
  justify-self: center;
  align-self: end;
}

.onboarding-progress {
  justify-self: center;
  align-self: end;
  grid-area: checker;
  width: 182px;
  height: 4px;
  border-radius: 16px;

}

.onboarding-description {
  grid-area: desc;
  justify-self: center;
  align-self: end;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  text-align: center;

  color: #FFFFFF;

  opacity: 0.4;
}

.onboarding-slider {
  grid-area: cards;
  align-self: center;
  align-content: center;
}


.onboarding-slider-items {

  li {
    opacity: 0.1;

    &.uk-active {
      opacity: 1;
    }
  }
}
</style>
