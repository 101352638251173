<template>
  <div class="ob__video">
    <Icon :size="48" :icon="videoIcon" />
    <p class="video__textlink" v-html="parserToHtml(videoLinkText)" />
  </div>
</template>

<script>
import Icon from "@/components/UI-Kit/Icon";
import { mdToHtml } from "@/setups/utility/mdSetup";
import { Icon as IconClass } from "@/components/UI-Kit/Helper classes/icon";

export default {
  name: "ObVideo",
  components: { Icon },
  props: {
    videoLinkText: {
      type: String
    }
  },
  setup: function() {
    const videoIcon = new IconClass(
      "img/icons/interface/video.png",
      "video icon",
      ""
    );
    const { parserToHtml } = mdToHtml();
    return {
      videoIcon,
      parserToHtml
    };
  }
};
</script>

<style scoped lang="scss">
.video__textlink {
  color: #333333;
  margin: 5px;
}
.ob__video {
  display: grid;
  grid-template-columns: 1fr 9fr;
  align-self: center;
}
</style>
