import { Remarkable } from "remarkable";

export const mdToHtml = function() {
  let md = new Remarkable();

  const parserToHtml = function(text) {
    return md.render(text);
  };

  return {
    parserToHtml
  };
};
