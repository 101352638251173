<template>
  <div class="ob__rules">
    <IconNew name="enter" class="rules__icon"/>
    <p class="rule__details">Hit Enter to add a line</p>
  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";

export default {
  name: "ObRules",
  components: {IconNew}
};
</script>

<style scoped lang="scss">
.rule__details {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  /* identical to box height, or 17px */


  color: #80869D;
}
.rules__icon {
  width: 16px;
  height: 16px;
}
p {
  margin: 0;
}
.ob__rules {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  column-gap: 8px;
  align-self: self-start;
  margin: 0;
}
</style>
